import {useState} from "react";
import {ConsumableBehaviorInterface, ConsumableType, CONSUMABLE_TYPES, ICollisionSettings} from "../../../../../types/editor";
import global from "../../../../../global";
import ConsumableBehaviorConverter from "../../../../../serialization/behaviours/ConsumableBehaviorConverter";
import {NumericInputRow} from "../common/NumericInputRow";
import {Separator} from "../common/Separator";
import {Instruction, PanelSectionTitleSecondary} from "../RightPanel.style";
import {SelectionOfButtons} from "../common/SelectionOfButtons";
import {Heading} from "../common/Heading";
import {COLLISION_SETTINGS, initialCollisionSettings} from "./helpers/collisionSettings";
import {DevPropertiesWrapper} from "../common/DevPropertiesWrapper";
import {PanelCheckbox} from "../common/PanelCheckbox";
import {ContentItem} from "../panels/GameSettings/GameSettings.style";
import {UploadField} from "../../common/UploadField/UploadField";
import {StyledButton} from "../../common/StyledButton";

type Props = {
    behavior: ConsumableBehaviorInterface;
};

export const ConsumableBehaviors = ({behavior}: Props) => {
    const app = (global as any).app;
    const editor = app.editor;
    const selected = editor.selected;
    const [behaviorState, setBehaviorState] = useState(behavior);

    const targetBehavior = ConsumableBehaviorConverter.DEFAULT.getBehavior(selected, behavior.id);

    const handleInputChange = (value: any, name: keyof ConsumableBehaviorInterface) => {
        if (selected && targetBehavior) {
            (targetBehavior as any)[name] = value;
            setBehaviorState({...targetBehavior, [name]: value});
            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleCollisionSettingsChange = (name: keyof ICollisionSettings) => {
        if (selected) {
            if (targetBehavior) {
                const value = !behaviorState.collisionSettings[name];
                targetBehavior.collisionSettings[name] = value;
                setBehaviorState({
                    ...targetBehavior,
                    collisionSettings: {
                        [name]: value,
                        ...behaviorState.collisionSettings,
                    },
                });
            }

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const handleConsumableTypeChange = (value: ConsumableType) => {
        if (selected) {
            if (targetBehavior) {
                targetBehavior.consumableType = value;
                setBehaviorState({...targetBehavior, consumableType: value});
            }

            app.call(`objectChanged`, app.editor, app.editor.selected);
            app.call(`objectUpdated`, app.editor, app.editor.selected);
        }
    };

    const tab = behaviorState.consumableType || CONSUMABLE_TYPES.INSTANT;

    return (
        <>
            <SelectionOfButtons margin="8px auto 0">
                <StyledButton
                    width="100px"
                    isBlue={tab === CONSUMABLE_TYPES.INSTANT}
                    isActive={tab !== CONSUMABLE_TYPES.INSTANT}
                    onClick={() => handleConsumableTypeChange(CONSUMABLE_TYPES.INSTANT)}>
                    <span>Instant</span>
                </StyledButton>
                <StyledButton
                    width="100px"
                    isBlue={tab === CONSUMABLE_TYPES.BUTTON_PRESS}
                    isActive={tab !== CONSUMABLE_TYPES.BUTTON_PRESS}
                    onClick={() => handleConsumableTypeChange(CONSUMABLE_TYPES.BUTTON_PRESS)}>
                    <span>Button Press</span>
                </StyledButton>
            </SelectionOfButtons>
            <Separator />
            {behaviorState.consumableType === CONSUMABLE_TYPES.BUTTON_PRESS && (
                <ContentItem style={{marginBottom: "12px"}}>
                    <PanelSectionTitleSecondary>Consumable UI Image</PanelSectionTitleSecondary>
                    <UploadField
                        width="80px"
                        height="80px"
                        uploadedFile={behaviorState.uiImage || null}
                        setUploadedFile={imageUrl => handleInputChange(imageUrl, "uiImage")}
                    />
                </ContentItem>
            )}
            <NumericInputRow
                width="75px"
                label="Point Amount"
                value={behaviorState.pointAmount}
                setValue={value => handleInputChange(value, "pointAmount")}
            />
            <NumericInputRow
                width="75px"
                label="Time Amount"
                value={behaviorState.timeAmount}
                setValue={value => handleInputChange(value, "timeAmount")}
            />
            <NumericInputRow
                width="75px"
                label="Health Amount"
                value={behaviorState.healthAmount}
                setValue={value => handleInputChange(value, "healthAmount")}
            />
            <NumericInputRow
                width="75px"
                label="Ammo Amount"
                value={behaviorState.ammoAmount}
                setValue={value => handleInputChange(value, "ammoAmount")}
            />
            <NumericInputRow
                width="75px"
                label="Shield Amount"
                value={behaviorState.shieldAmount}
                setValue={value => handleInputChange(value, "shieldAmount")}
            />
            <NumericInputRow
                width="75px"
                label="Money Amount"
                value={behaviorState.moneyAmount}
                setValue={value => handleInputChange(value, "moneyAmount")}
            />
            <NumericInputRow
                width="75px"
                label="Scale % Amount"
                value={behaviorState.scaleAmount}
                setValue={value => handleInputChange(value, "scaleAmount")}
            />
            <NumericInputRow
                width="75px"
                label="Speed % Amount"
                value={behaviorState.speedAmount}
                setValue={value => handleInputChange(value, "speedAmount")}
            />
            <NumericInputRow
                width="75px"
                label="Time of Effect"
                value={behaviorState.timeForEffect_speed}
                setValue={value => handleInputChange(value, "timeForEffect_speed")}
            />
            <NumericInputRow
                width="75px"
                label="Jump % Amount"
                value={behaviorState.jumpAmount}
                setValue={value => handleInputChange(value, "jumpAmount")}
            />
            <NumericInputRow
                width="75px"
                label="Time of Effect"
                value={behaviorState.timeForEffect_jump}
                setValue={value => handleInputChange(value, "timeForEffect_jump")}
            />
            <Separator />
            <Heading margin={"0 0 12px 0"}>Collision Settings</Heading>
            <SelectionOfButtons>
                {COLLISION_SETTINGS.map(item => {
                    if (!behaviorState.collisionSettings) {
                        behaviorState.collisionSettings = initialCollisionSettings;
                    }
                    const currentSetting = behaviorState.collisionSettings[item.propName];

                    return (
                        <StyledButton
                            width="109px"
                            isBlueSecondary={currentSetting}
                            isActive={!currentSetting}
                            onClick={() => handleCollisionSettingsChange(item.propName)}
                            style={{textWrap: "nowrap"}}
                            key={item.label}>
                            <span>{item.label}</span>
                        </StyledButton>
                    );
                })}
            </SelectionOfButtons>
            <Separator />
            {behaviorState.collisionSettings.canReappear && (
                <>
                    <NumericInputRow
                        width="75px"
                        label="Time to show again"
                        value={behaviorState.timeToShowAgain || 10}
                        setValue={value => handleInputChange(value, "timeToShowAgain")}
                    />
                    <Separator />
                </>
            )}
            {behaviorState.consumableType === CONSUMABLE_TYPES.BUTTON_PRESS && (
                <Instruction>
                    <div className="text">E key: to Pick up</div>
                    <div className="text">F key: to remove from inventory</div>
                </Instruction>
            )}
            {behaviorState.consumableType === CONSUMABLE_TYPES.INSTANT && (
                <DevPropertiesWrapper>
                    <PanelCheckbox
                        text="Collision Detection Via Bounding Boxes"
                        checked={!!behaviorState.useBoundingBoxes}
                        onChange={() => handleInputChange(!behaviorState.useBoundingBoxes, "useBoundingBoxes")}
                    />
                </DevPropertiesWrapper>
            )}
        </>
    );
};
