import * as THREE from "three";
import { Object3D } from "three";
import { OBJECT_TYPES, COLLISION_TYPE, ConsumableBehaviorInterface, CONSUMABLE_TYPES } from "../../types/editor";
import GameManager from "../../behaviors/game/GameManager";
import { PhysicsUtil } from "../../physics/PhysicsUtil";
import EventBus from "../../behaviors/event/EventBus";
import { BehaviorUpdater } from "../../behaviors/BehaviorManager";
import RangeDetector from "../../behaviors/range/RangeDetector";

export default class consumableBehaviorUpdaterBase implements BehaviorUpdater {
    private game?: GameManager;
    public target: Object3D;
    private usingPhysics: boolean = false;
    private isDynamic: boolean = false;
    private behavior: ConsumableBehaviorInterface;
    private removed: boolean = false;
    private currentIndex: number = 0;
    private objectDivs: HTMLElement[] = [];
    private objectImages: HTMLImageElement[] = [];
    private objectDivContainer: HTMLElement | null = null;
    private count: number = 0;
    private isKeyListenerAdded: boolean = false;
    private currentSelectedObject: Object3D | null = null;
    rangeDetector: RangeDetector;

    constructor(target: Object3D, behavior: ConsumableBehaviorInterface) {
        this.target = target;
        this.behavior = behavior;
        this.rangeDetector = null as unknown as RangeDetector;
    }

    init(gameManager: GameManager) {
        this.game = gameManager;
        this.usingPhysics = PhysicsUtil.isPhysicsEnabled(this.target);
        this.isDynamic = PhysicsUtil.isDynamicObject(this.target);
        this.addCollisionListener();
        this.addKeyListener();
        this.loadCollectableUI();
        if (gameManager.scene) {
            this.rangeDetector = new RangeDetector(gameManager);
        } 
        this.addRangeDetector();
        
    }

    addCollisionListener() {
        let collisionType = this.getCollisionType();
        if (collisionType !== COLLISION_TYPE.UNKNOWN) {
            this.game!.behaviorManager?.collisionDetector.addListener(
                this.target,
                {
                    type: collisionType,
                    callback: this.onCollision.bind(this),
                    useBoundingBoxes: this.behavior.useBoundingBoxes,
                },
                this.isDynamic,
            );
        } else {
            console.warn("Collision type is not specified for " + this.target.name);
        }
    }

    getCollisionType(): COLLISION_TYPE {
        if (!this.behavior.collisionSettings) return COLLISION_TYPE.UNKNOWN;
        return this.behavior.collisionSettings.playerCollision
            ? COLLISION_TYPE.WITH_PLAYER
            : this.behavior.collisionSettings.throwableCollision
                ? COLLISION_TYPE.WITH_COLLIDABLE_OBJECTS
                : this.behavior.collisionSettings.enemyCollision
                    ? COLLISION_TYPE.WITH_ENEMY
                    : COLLISION_TYPE.UNKNOWN;
    }

    updateGameState() {
        if (this.behavior.healthAmount > 0) {
            EventBus.instance.send("game.lives.inc", this.behavior.healthAmount);
        }
        if (this.behavior.pointAmount > 0) {
            EventBus.instance.send("game.score.inc", this.behavior.pointAmount);
        }
        if (this.behavior.timeAmount > 0) {
            EventBus.instance.send("game.time.inc", this.behavior.timeAmount);
        }
    }

    addKeyListener() {
        if (this.isKeyListenerAdded) return;

        // Key 'C' toggles through objects
        document.body.addEventListener("keydown", (event) => {
            if (event.key.toLowerCase() === "c") {
                this.toggleNextObject();
            }
        });

        // Key 'F' removes the selected object
        document.body.addEventListener("keydown", (event) => {
            if (event.key.toLowerCase() === "f") {
                this.removeCollectableFromInventory();
            }
        });

        // Key 'E' allows collection for 'Button Press' consumables
        document.body.addEventListener("keydown", (event) => {
            if (event.key.toLowerCase() === "e") {
                if (this.target && this.target.userData.behaviors) {
                    const consumableBehavior = this.target.userData.behaviors.find(
                        (behavior: any) => behavior.type === OBJECT_TYPES.CONSUMABLE
                    );

                    if (consumableBehavior && consumableBehavior.consumableType === CONSUMABLE_TYPES.BUTTON_PRESS) {
                        this.target.userData.isButtonPressAllowed = true;
                    }
                }
            }
        });

        //Reset key press event
        document.body.addEventListener("keyup", (event) => {
            this.target.userData.isButtonPressAllowed = false;
        });

        this.isKeyListenerAdded = true;
    }

    onCollision() {
     
        if (!this.target || !this.target.userData || !this.target.userData.behaviors) return;

        const consumableBehavior = this.target.userData.behaviors.find(
            (behavior: any) => behavior.type === OBJECT_TYPES.CONSUMABLE
        );

        if (consumableBehavior && consumableBehavior.consumableType === CONSUMABLE_TYPES.BUTTON_PRESS) {
            if (!this.target.userData.isButtonPressAllowed) {
                return; 
            }
        }

        if (this.target.userData.isCollected) {
            if (this.target.userData.isDropped) {
                this.target.userData.isDropped = false;
            }
            return;
        }

      
        this.target.userData.isCollected = true;
        this.target.visible = false;

        this.game?.scene?.traverse((object: Object3D) => {
            if (object.userData && object.userData.behaviors) {
                const behavior = object.userData.behaviors.find(
                    (b: any) => b.type === OBJECT_TYPES.CONSUMABLE
                );
                if (behavior) {
                    this.count++;
                    object.userData.index = this.count;
                    this.currentIndex = (this.currentIndex % this.count) + 1;
                    object.userData.isCollectableInventoryItem = true;
                }
            }
        });
    }


    addRangeDetector() {
        this.rangeDetector.setPlayer(this.game!.player!);
        this.rangeDetector.addTargets(this.target);
    }

    toggleNextObject() {
        const visibleObjects: Object3D[] = [];

        this.game?.scene?.traverse((object: Object3D) => {
            if (object.userData && object.userData.isCollected && !object.userData.isDropped) {
                if (object.userData.isCollectableInventoryItem) {
                    visibleObjects.push(object);
                }
            }
        });

        this.currentIndex = (this.currentIndex + 1) % (visibleObjects.length + 1);

        if (this.currentIndex === visibleObjects.length) {
            this.currentSelectedObject = null;

            visibleObjects.forEach((object) => {
                const objectDiv = document.getElementById(`consumable-${object.uuid}`);
                if (objectDiv) {
                    object.visible = false;
                    objectDiv.style.border = "none";
                }
            });

            return;
        }

        this.currentSelectedObject = visibleObjects[this.currentIndex];

        visibleObjects.forEach((object, index) => {
            const objectDiv = document.getElementById(`consumable-${object.uuid}`);
            if (objectDiv) {
                if (index === this.currentIndex) {
                    object.visible = true;
                    objectDiv.style.border = "2px solid white";
                } else {
                    object.visible = false;
                    objectDiv.style.border = "none";
                }
            }
        });
    }


    removeCollectableFromInventory() {

        if (!this.currentSelectedObject) {
            return;
        }

        const object = this.currentSelectedObject;

        if (object.userData.originalPosition) {
            object.position.copy(object.userData.originalPosition);
        }
        object.userData.isDropped = true;
        object.userData.isCollected = false;
        object.visible = true;


        const objectDiv = document.getElementById(`consumable-${object.uuid}`);
        if (objectDiv) {
            objectDiv.style.border = "none";
            objectDiv.style.display = "none";
            object.userData.isCollectableInventoryItem = false;
        }

        this.currentSelectedObject = null;
    }


    update(clock: THREE.Clock, delta: number) {
        if (this.target.userData.isCollected) {
            const objectDiv = document.getElementById(`consumable-${this.target.uuid}`);
            if (this.target.userData.isDropped) {
                this.target.position.copy(this.target.userData.originalPosition);
                objectDiv!.style.display = "none";
            } else {
                this.target.position.copy(this.game!.player!.position);
                const offset = new THREE.Vector3(0, 0, 1);
                offset.applyQuaternion(this.game!.player!.quaternion);
                this.target.position.add(offset);
                objectDiv!.style.display = "block";
            }
        }


        if (this.target.userData && this.target.userData.behaviors && !this.target.userData.uiIconLoaded) {
            const consumableBehavior = this.target.userData.behaviors.find(
                (behavior: any) => behavior.type === OBJECT_TYPES.CONSUMABLE
            );

            if (consumableBehavior) {
                const collectableIconImage = document.getElementById(`consumable-image-${this.target.uuid}`) as HTMLImageElement;
                const collectableLabelDIV = document.getElementById(`consumable-name-${this.target.uuid}`) as HTMLDivElement;
                if (collectableIconImage && collectableLabelDIV) {
                    //show a transparent image if no consumable icon is found
                    collectableIconImage.src = consumableBehavior.uiImage || "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAFyALMfgF25wAAAABJRU5ErkJggg==";
                    this.target.userData.uiIconLoaded = true;
                }
            }
        }

        this.rangeDetector.update();
      
    }

    loadCollectableUI() {

        const consumableObjects: { object: Object3D; consumableBehavior: any }[] = [];
        this.game?.scene?.traverse((object: Object3D) => {
            if (object.userData && object.userData.behaviors && !object.userData.collectibleLoaded) {
                const consumableBehavior = object.userData.behaviors.find(
                    (behavior: any) => behavior.type === OBJECT_TYPES.CONSUMABLE
                );
                if (consumableBehavior) {
                    // Save the original position in userData
                    object.userData.originalPosition = object.position.clone();

                    consumableObjects.push({ object, consumableBehavior });
                    object.userData.collectibleLoaded = true;
                }
            }
        });


        const objectDivContainer = document.createElement("div");
        objectDivContainer.id = `consumable-container`;
        if (!this.objectDivContainer) {
            objectDivContainer.style.position = "fixed";
            objectDivContainer.style.top = "20px";
            objectDivContainer.style.left = "50%";
            objectDivContainer.style.transform = "translateX(-50%)";
            objectDivContainer.style.display = "flex";
            objectDivContainer.style.flexDirection = "row";
            objectDivContainer.style.flexWrap = "wrap";
            objectDivContainer.style.justifyContent = "flex-start";
            objectDivContainer.style.gap = "10px";
            objectDivContainer.style.zIndex = "100";
            objectDivContainer.style.maxWidth = "90%";
            document.body.appendChild(objectDivContainer);
        }

        consumableObjects.forEach(({ object }) => {

            const objectDiv = document.createElement("div");
            const collectableID = `consumable-${object.uuid}`;
            objectDiv.id = collectableID;
            objectDiv.style.display = "flex";
            objectDiv.style.flexDirection = "column";
            objectDiv.style.alignItems = "center";
            objectDiv.style.marginRight = "10px";
            objectDiv.style.display = 'none'
            objectDiv.style.marginBottom = "10px";

            const objectImage = document.createElement("img");
            const objectImageID = `consumable-image-${object.uuid}`;
            objectImage.id = objectImageID;
            objectImage.alt = object.name || "Stem Studio Consumable Game Icon";
            objectImage.style.width = "50px";
            objectImage.style.height = "50px";
            objectImage.style.marginBottom = "5px";
            objectDiv.appendChild(objectImage);

            const objectNameDiv = document.createElement("div");
            objectNameDiv.textContent = object.name || "Unnamed Object";
            const objectNameDivID = `consumable-name-${object.uuid}`;
            objectNameDiv.id = objectNameDivID;
            objectNameDiv.style.color = "white";
            objectNameDiv.style.fontSize = "10px";
            objectNameDiv.style.textAlign = "center";
            objectNameDiv.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
            objectNameDiv.style.padding = "5px";
            objectNameDiv.style.borderRadius = "5px";
            objectDiv.appendChild(objectNameDiv);
            try {
                objectDivContainer.appendChild(objectDiv);
            } catch {

            }

            this.objectDivs.push(objectDiv);
            this.objectImages.push(objectImage);

        });
    }

    reset() {
        if (this.removed) {
            if (this.usingPhysics) {
                this.game!.app.addPhysicsObject(this.target);
            } else {
                this.game!.scene?.add(this.target);
            }
            this.addCollisionListener();
            this.removed = false;
        }
    }
}
